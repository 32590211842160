import Image, { ImageProps } from '@components/Image';
import React from 'react';
import Txt from '@primitive/Txt';
import * as style from './ImageWithCaption.css';
import Box, { BoxProps } from '@primitive/Box';

export type ImageWithCaptionProps = BoxProps<
  'figure',
  {
    imageProps: ImageProps;
    caption?: string | null;
  }
>;

const ImageWithCaption = ({ imageProps, caption, ...props }: ImageWithCaptionProps) => {
  return (
    <Box as="figure" {...props}>
      <Image alt="" rounded {...imageProps} />
      <Txt as="figcaption" className={style.caption}>
        {caption}
      </Txt>
    </Box>
  );
};

export default ImageWithCaption;
