// import Swiper core and required modules
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import IconButton from '@components/IconButton';
import Box from '@primitive/Box';
import { useBreakpointMin } from '@theme';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSwiper } from 'swiper/react';
import * as style from './Carousel.css';
import Flex from '@componentPrimitives/Flex';
import { useState } from 'react';

const CarouselNavigation = () => {
  const swiper = useSwiper();

  const handleNext = () => {
    swiper.slideNext();
  };
  const handlePrev = () => {
    swiper.slidePrev();
  };

  return (
    <Box className={style.navigation}>
      <IconButton
        className={style.navigationPrev}
        rounded={false}
        disableBorder
        variant="outlined"
        icon="chevronLeft"
        onClick={handlePrev}
      />
      <IconButton
        className={style.navigationNext}
        rounded={false}
        disableBorder
        variant="outlined"
        icon="chevronRight"
        onClick={handleNext}
      />
    </Box>
  );
};

export const CarouselPagination = ({
  slides,
  activeIndex,
}: {
  slides: any[];
  activeIndex: number;
}) => {
  return (
    <Flex className={style.pagination} direction="row" justifyContent="center" spacing="3xs">
      {slides.map((_, i) => (
        <Box
          key={i}
          role="button"
          aria-label={`slide ${i + 1}`}
          className={style.paginationBullet({
            active: i === activeIndex,
          })}
        />
      ))}
    </Flex>
  );
};

export type CarouselProps = {
  items: JSX.Element[];
  hideNavigation?: boolean;
  // disablePagination?: boolean
};

const Carousel = (props: CarouselProps) => {
  const { items = [], hideNavigation } = props;

  const isMobile = !useBreakpointMin('md');
  const [activeIndex, setActiveIndex] = useState(0);

  if (!items.length) return <></>;
  return (
    <Swiper
      modules={[Pagination, A11y]}
      slidesPerView={1}
      loop
      className=""
      onSlideChange={(s) => setActiveIndex(s.activeIndex)}
      pagination={{
        clickable: true,
        enabled: false,
      }}>
      {items.map((item, i) => (
        <SwiperSlide key={i}>
          <Box
            sx={
              {
                // paddingX: 10,
              }
            }>
            {item}
          </Box>
        </SwiperSlide>
      ))}
      <Box>{!hideNavigation && !isMobile && <CarouselNavigation />}</Box>
      {/* <CarouselPagination activeIndex={activeIndex} slides={items} /> */}
    </Swiper>
  );
};

export default Carousel;
