import { BlockContainer } from '@components/Blocks';
import Carousel from '@components/Carousel';
import ImageWithCaption from '@components/ImageWithCaption';
import { createBlock } from '@lib/features/blocks/blocks';

const ImageCarouselBlock = createBlock<'blocks_imageCarousel_BlockType'>(({ children = [] }) => {
  return (
    <BlockContainer maxWidth="md">
      <Carousel
        items={children?.map((v, i) => (
          <ImageWithCaption
            key={i}
            imageProps={{
              ...v.image,
              ratio: 'landscape',
            }}
            caption={v.caption}
          />
        ))}
      />
    </BlockContainer>
  );
});

export default ImageCarouselBlock;
